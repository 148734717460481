import React from "react";
import tw from "twin.macro";
import {ReactComponent as EmailNewsletterIconBase } from "../../images/email-icon.svg"
import {Container as ContainerBase } from "components/misc/Layouts.js"
import {SectionHeading} from "components/misc/Headings.js";
import Iframe from 'react-iframe';


const Container = tw(ContainerBase)`bg-secondary-800 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`

const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-16 h-16 text-primary-200`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-lightest sm:text-left leading-none`
const Description = tw.p`text-gray-300 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`

export default () => {
  return (
    <Container id="newsletter">
      <Content>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>Newsletter</Heading>
              <Description>Sign up for our newsletter to be the first to hear about new features, exclusive discounts, and more.</Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
          <Iframe 
            url="https://embeds.beehiiv.com/6e7296ac-d4f5-4dee-9f5f-9261d082a293?slim=true"
            width="100%"
            height="52px"
            id="myId"
            display="initial"
            position="relative"
          />
          </FormColumn>
        </Row>
      </Content>
    </Container>
  );
};
