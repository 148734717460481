import React from "react";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/Header.js";

import { HashLink } from 'react-router-hash-link';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-start max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

export const NavLink = tw(HashLink)`
  text-2xl my-2 lg:text-xl lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded-xl bg-primary-500 text-gray-lightest
  hocus:bg-primary-700 hocus:text-gray-lightest
  border-b-0
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Digitize your collections and <span tw="text-primary-500">take them everywhere you go.</span>
            </Heading>
            <Paragraph>
              Classifier was created for collectors of <b>pretty much anything</b> who want to show off their collections without the hassle of having to physically take them everywhere.
            </Paragraph>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} to="/download">Download for Free</PrimaryLink>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src="images/website_hero.png" alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
