import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faFilter, faCheckCircle, faCoins, faShare, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 bg-gray-lightest rounded-3xl mt-12 shadow-raised`}
  .imageContainer {
    ${tw`text-center rounded-full p-6 flex-shrink-0 relative`}
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-gray-800`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

export default () => {

  const cards = [
    { icon: faCheckCircle, title: "Create Wishlists", description: "Keep on top of those sought-after collectibles" },
    { icon: faFilter, title: "Tag and Filter", description: "Filter your collectibles at the tap of a button" },
    { icon: faChartPie, title: "Create Statistics", description: "Understand the data behind your collections" },
    { icon: faCoins, title: "Support for all Collections", description: "Classifier has the flexibility to work for any type of collection" },
    { icon: faShare, title: "Export and Share", description: "Share your entire collection with the world by exporting it to your photo library" },
    { icon: faCloudDownloadAlt, title: "Sync Across Devices", description: "Access your collections from any supported device" }
  ];

  return (
    <Container id="features">
      <ThreeColumnContainer>
        <Heading>App Features</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <FontAwesomeIcon icon={card.icon} size="3x" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
