import React from "react";
import tw from "twin.macro";

import Hero from "components/hero/Hero.js";
import Features from "components/features/Features.js";
import Pricing from "components/pricing/Pricing.js";
import Newsletter from "components/newsletter/Newsletter.js";
import Footer from "components/footers/Footer.js";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => (
  <StyledDiv>
    <Hero />
    <Features />
    <Pricing />
    <Newsletter />
    <Footer />
  </StyledDiv>
);
