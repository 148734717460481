import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-md sm:inline-block sm:w-auto border-2 rounded-2xl px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/3 sm:w-32 px-4 sm:px-8 py-3 rounded-xl focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-lightest`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-3xl relative text-gray-900 bg-white flex flex-col shadow-raised`}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-3xl`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-2xl sm:text-2xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

export default ({
  subheading = "",
  heading = "Pricing",
  description = "Sign up to our newsletter to be the first to hear about pricing updates.",
  plans = null,
  primaryButtonText = "Buy Now",
  planDurations = [
    {
      text: "3 months",
      switcherText: "Quarterly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    },
    {
      text: "Lifetime",
      switcherText: "Lifetime"
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "Free",
      durationPrices: ["$0", "$0", "$0"],
      mainFeature: "Some Limits",
      features: ["Account Limits", "2 Collections", "20 Collectibles per Collection", "Sync Across Devices", "No Widgets"]
    },
    {
      name: "Classifier Pro",
      durationPrices: ["US$2.99", "US$9.99", "US$29.99"],
      mainFeature: "No Limits",
      features: ["Unlimited Collections", "Unlimited Collectibles", "Unlimited Charts", "Sync Across Devices", "Lock Screen Widgets"],
      featured: true
    },
    {
      name: "Classifier Pro (Family)",
      durationPrices: ["US$4.99", "US$16.99", "US$44.99"],
      mainFeature: "No Limits",
      features: ["Unlimited Collections", "Unlimited Collectibles", "Unlimited Charts", "Sync Across Devices", "Lock Screen Widgets", "Share subscription with family"],
      featured: true
    }
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <Container id="pricing">
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        <PlanDurationSwitcher>
          {planDurations.map((planDuration, index) => (
            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
          ))}
        </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration">
                  <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
                  <span className="slash"> / </span>
                  <span className="duration">{planDurations[activeDurationIndex].text}</span>
                </span>
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {activeDurationIndex === 1 && index === 1 ? <span key={index} className="feature">
                    {"7 Day Free Trial"}
                  </span> : null}

                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
